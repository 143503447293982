body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
    font-family: 'Sanchez', serif, sans-serif !important;
    border: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
    font-size: 2rem !important;
}

h2 {
    font-size: 1.2rem !important;
}

ul.nav {
    white-space: nowrap;
    overflow: auto;
    display: block;
    margin: 0px 20px;
    padding-bottom: 15px;
}

ul.nav li {
    display: inline-block;
    float: none;
}

.footerText {
    text-align:center;
}

.menuSpotLeft {
    padding: 0px !important;
    height: 200px;
    background-size: cover;
}

.menuSpotRight {
    padding: 0px !important;
    height: 200px;
    background-size: cover;
}

.row button {
    width:100%;
    height: 200px;
    border-style:none
}

.homePageHeaderSpot {
    margin-bottom: 0px !important;
    margin-top: 70px;
    background-color: #ffffff !important;
}

.menuHeader {
    margin-top: 70px;
    background-color: #ffffff !important;
}

.menuSpotsContainer {
    padding: 0px !important;
}

.navbar-toggler {
    border-width:0px !important;
}

.nav-link {
    color: #185a37;
}

.nav-link.active {
    background-color: #185a37 !important;
}

.card-header {
    background-color: #c60404 !important;
    color: #ffffff;
}

.menuSpotsRow {
    margin: 0 0 !important;
}
.card-title {
    margin-bottom: 0 !important;
}

.menuContent {
    min-height: calc(100vh - 217px);
}

.twoSpot {
    padding: 0 0 !important;
}

.cardHome {
    border: 0 !important;
}

.specialCard {
    border: 0 !important;
    box-shadow: 5px 5px 15px rgba(70, 70, 70, .6);
}

.square {
    border-radius: 0% !important;
}
.footerCard {
    border-color: #2f2f2f !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.navbar {
    background-color: rgba(255, 255, 255, .95);
}
